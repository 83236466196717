import { format, parseISO, differenceInCalendarDays, isSameYear, getYear, isValid } from 'date-fns'

// some helper functions

// --------------------------------------------------------------------------
export function formatISO (date) {
  return format(date, "yyyy-MM-dd'T'HH:mm:ssXX"
  )
}

// --------------------------------------------------------------------------
export function prettyT24 (dt) {
  dt = convertDT(dt)
  if (dt == "") return
  try {
    if (getYear(dt) == 0) {
      // year 0001, comes out as zero (the "empty" date for most uses)
      return ""
    }
    return format(dt, "HH:mm")
  } catch (e) {
    console.log("Bad datetime:", dt, e)
    return ""
  }
}

export function prettyT (dt) {
  dt = convertDT(dt)
  if (dt == "") return
  try {
    if (getYear(dt) == 0) {
      // year 0001, comes out as zero (the "empty" date for most uses)
      return ""
    }
    return format(dt, "h:mmaa")
  } catch (e) {
    console.log("Bad datetime:", dt, e)
    return ""
  }
}

export function prettyDT (dt) {
  dt = convertDT(dt)
  if (dt == "") return
  try {
    if (getYear(dt) <= 1900) {
      // year 0001, comes t stout as zero (the "empty" date for most uses)
      // the difference call below returns weird stuff for this date,
      // so this test comes before it
      return ""
    }
    let today = Date.now()
    let days = differenceInCalendarDays(today, dt)
    if (days == 0) {
      // same day
      return format(dt, "h:mm aa")
    }
    if (days < 0) {
      // future date
      if (isSameYear(dt, today)) {
        // same year
        return format(dt, "MMM-d h:mmaa EEE")
      }
      // default, full format
      return format(dt, "yyyy-MM-dd h:mmaa EEE")
    }
    if (days <= 6) {
      // within the last week
      return format(dt, "h:mmaa EEE")
    }
    if (isSameYear(dt, today)) {
      // same year
      return format(dt, "MMM-d h:mmaa EEE")
    }
    // default, full format
    return format(dt, "yyyy-MM-dd h:mmaa EEE")
  } catch (e) {
    console.log("Bad datetime:", dt, e)
    return ""
  }
}

export function prettyDay (dt) {
  dt = convertDT(dt)
  if (dt == "") return
  try {
    if (getYear(dt) == 0) {
      // year 0001, comes out as zero (the "empty" date for most uses)
      // the difference call below returns weird stuff for this date,
      // so this test comes before it
      return ""
    }
    return format(dt, "EEE MM/dd")
  } catch (e) {
    console.log("Bad datetime:", dt, e)
    return ""
  }
}

export function convertDT (dt) {
  if (typeof dt === "undefined" || dt == null) {
    return ""
  }
  if (typeof dt === "string") {
    try {
      dt = parseISO(dt)
    } catch (e) {
      console.log("Bad datetime:", dt, e)
      return ""
    }
  }
  return dt
}

// --------------------------------------------------------------------------
/*
export function financial (x) {
  return Number.parseFloat(x).toFixed(2)
}

export function financialB (x) {
  var fin = Number.parseFloat(x).toFixed(2)
  if (fin == '0.00') {
    return ''
  }
  return fin
}
*/

export function financialIB (i) {
  var fin = financialI(i)
  if (fin == '0.00') {
    return ''
  }
  return fin
}

export function financialI (i) {
  i = i + "" // to string
  if (i.length < 3) {
    i = "0" + i
  }
  if (i.length < 3) {
    i = "0" + i
  }
  var dec = i.slice(-2)
  var whole = i.slice(0, -2)
  i = whole + '.' + dec
  return i
}

// --------------------------------------------------------------------------
export function strToInt (str) {
  var result = str.match(/\d/igm) // array of digit chars
  if (result == null) {
    return 0 // default result on any unacceptable input
  }
  result = result.join("") // array to string
  result = Number(result) // string to num
  return result
}

// --------------------------------------------------------------------------
export function getHourColor (hour, approved) {
  if (approved) {
    return ''
  }
  if (hour.error) {
    return 'red--text font-weight-bold'
  }
  if (hour.warn) {
    return 'orange--text font-weight-bold'
  }
  return ''
}

export function getHoursBlank (hour) {
  if (hour.hours == 0 && !hour.error && !hour.warn) {
    return financialIB(hour.hours)
  }
  return financialI(hour.hours)
}

export function isBlank (item) {
  if (item.clockin == '' && item.clockout == '') {
    return true
  }
  return false
}

// --------------------------------------------------------------------------
// A simple test for empty datetime (i.e. year 0000 or 0001)
export function isZeroDate (dt) {
  if (!isValid(dt)) return true
  // year 0001, comes out as zero (the "empty" date for most uses)
  return getYear(dt) === 0
}

// Returns true if two datetimes are on different calendar days
export function isDiffDay (t1, t2) {
  t1 = convertDT(t1)
  t2 = convertDT(t2)
  if (isZeroDate(t1) || isZeroDate(t2)) return false
  return differenceInCalendarDays(t1, t2) !== 0
}
